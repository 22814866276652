import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from '@components/Container'
import Hero from '@components/Hero'
import PageComponents from '@components/PageComponents'
import PostList from '@components/PostList'
import Pagination from '@components/Pagination'
import Footer from '@components/Footer'
import * as S from '@styles/components/Archive'
import SEO from '@components/partials/SEO'
import { metaData } from '@fragments/meta'

export default class IndexPage extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      allWordpressPost: PropTypes.shape({
        edges: PropTypes.array,
      }),
    }),
    pageContext: PropTypes.shape({
      currentPage: PropTypes.number,
      numPages: PropTypes.number,
    }),
  }

  constructor(props) {
    super(props)
    this.useFlyout = false
  }

  render() {
    const { data, pageContext } = this.props
    const {
      allWordpressPost: { edges: posts },
      wordpressPage: { yoast_meta: meta, ...page },
      allWordpressCategory: { edges: categories },
    } = data

    page.acf.hero.type = 'basic'
    if (page.acf.hero && !page.acf.hero.heading) {
      page.acf.hero.heading = page.title
    }

    return (
      <Container page={page}>
        <SEO {...meta} path={page.path} />
        {page.acf.hero && <Hero {...page.acf.hero} {...page} />}
        <main>
          {page.acf.content_page && (
            <article>
              <PageComponents components={page.acf.content_page} />
            </article>
          )}
          <S.PageWrapper>
            <S.Archive>
              <PostList
                type="post"
                posts={posts}
                categories={categories}
                useFlyout={this.useFlyout}
              />
              <Pagination pageContext={pageContext} pathPrefix="/blog" />
            </S.Archive>
          </S.PageWrapper>
        </main>
        <Footer />
      </Container>
    )
  }
}

// TODO: Use GraphQL directives to customize query based on presence of featured post
export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    # featuredPost: allWordpressPost(
    #   sort: { fields: date, order: DESC }
    #   limit: 1
    #   skip: 0
    # ) {
    #   edges {
    #     node {
    #       ...PostListFields
    #     }
    #   }
    # }
    # allPosts: allWordpressPost(
    #   sort: { fields: date, order: DESC }
    #   limit: $limit
    #   skip: $skip
    # ) {
    #   edges {
    #     node {
    #       ...PostListFields
    #     }
    #   }
    # }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          id
          name
          path
        }
      }
    }
    wordpressPage(slug: { eq: "blog" }) {
      title
      path
      acf {
        hero {
          ...HeroFragment
        }
        content_page {
          __typename
          ...BannerComponentFragment
          ...CardsComponentFragment
          ...FeaturedProjectsComponentFragment
          ...FormComponentFragment
          ...ImageComponentFragment
          ...ImageBlocksComponentFragment
          ...ListBlockComponentFragment
          ...LogoGridComponentFragment
          ...SliderComponentFragment
          ...TeamComponentFragment
          ...TestimonialsComponentFragment
          ...TextComponentFragment
          ...TextWithImagesFragment
          ...TilesComponentFragment
        }
      }
      yoast_meta {
        ...metaData
      }
    }
  }
`
