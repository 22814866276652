import React, { Component } from 'react'
import { Link } from '@partials/Link'
import * as S from '@styles/components/Pagination'

class Pagination extends Component {
  constructor(props) {
    super(props)

    const {
      pathPrefix,
      pageContext: { numberOfPages },
    } = this.props

    this.pages = {
      1: pathPrefix,
    }

    if (numberOfPages > 1) {
      for (let i = 2; i <= this.props.pageContext.numberOfPages; i++) {
        this.pages[i] = `${pathPrefix}/page/${i}`
      }
    }
  }

  render() {
    const { pageContext, simple } = this.props
    const { previousPagePath, nextPagePath } = pageContext

    return (
      <nav className="pagination" role="navigation">
        {/* TODO: Change to */}
        <S.Pagination>
          {previousPagePath && (
            <S.PaginationItem key="prev">
              <Link to={previousPagePath} rel="prev">
                &#8249; Previous
              </Link>
            </S.PaginationItem>
          )}
          {!simple &&
            Object.entries(this.pages).map(([index, path]) => (
              <S.PaginationItem key={index}>
                <Link to={path} rel="prev">
                  {index}
                </Link>
              </S.PaginationItem>
            ))}
          {nextPagePath && (
            <S.PaginationItem key="next">
              <Link to={nextPagePath} rel="next">
                Next &#8250;
              </Link>
            </S.PaginationItem>
          )}
        </S.Pagination>
      </nav>
    )
  }
}

export default Pagination
