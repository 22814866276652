import styled from 'styled-components'
import { tokens, media, spacing } from '@tokens'
import { ITCBold } from '@styles/Typography'

export const Pagination = styled.ul`
  ${ITCBold};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding-top: ${spacing(3)};

  ${media.greaterThan('s')`
    padding-top: ${spacing(4)};
  `}

  ${media.greaterThan('l')`
    padding-top: ${spacing(5)};
  `}
`

export const PaginationItem = styled.li`
  display: block;
  line-height: 1.5;
  position: relative;
  text-overflow: ellipsis;
  margin: 0 ${spacing(1)};

  a {
    color: ${tokens.color('text')};

    &:hover {
      color: ${tokens.color('link', 'hover')};
    }

    &:focus {
      color: ${tokens.color('link', 'focus')};
    }

    &:active,
    &.is-active {
      color: ${tokens.color('link', 'active')};
    }
  }
`
